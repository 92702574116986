








































































































import {Component, Vue} from 'vue-property-decorator'
import ChuFangApi from '@/api/chufang'

@Component({})
export default class BodyAnalysis extends Vue {
  private api = new ChuFangApi()
  private isFinish = false
  private bodyAnalysis = {}

  private explainContent1 = false
  private explainContent2 = false
  private explainContent3 = false
  private explainContent4 = false
  private explainContent5 = false
  private explainContent6 = false
  private explainContent7 = false
  private explainContent8 = false
  private explainContent9 = false
  private explainContent10 = false
  private explainContent11 = false
  private explainContent12 = false

  private async activated() {
    await this.getLastInfo()
  }

  private deactivated() {
  }

  private async getLastInfo() {
    const result = await this.api.getLastAssessment(true)
    if (!result) {
      this.$toast('请先完成自我评估检测')
    } else {
      this.isFinish = true
      this.bodyAnalysis = Object.assign({}, result)
      this.checkAnalysis(result)
    }
  }

  private checkAnalysis(body: any) {
    if (
      body.bmiStr === '正常' &&
      (body.fatStr === '极好' || body.fatStr === '良好' || body.fatStr === '一般') &&
      (body.whrStr === '中' || body.whrStr === '低')
    ) {
      this.explainContent1 = true
    } else if (
      body.bmiStr === '正常' &&
      (body.fatStr === '较胖' || body.fatStr === '很胖') &&
      (body.whrStr === '中' || body.whrStr === '低')
    ) {
      this.explainContent2 = true
    } else if (
      body.bmiStr === '正常' &&
      (body.fatStr === '极好' || body.fatStr === '良好' || body.fatStr === '一般') &&
      (body.whrStr === '高')
    ) {
      this.explainContent3 = true
    } else if (
      body.bmiStr === '正常' &&
      (body.fatStr === '较胖' || body.fatStr === '很胖') &&
      (body.whrStr === '高')
    ) {
      this.explainContent4 = true
    } else if (body.bmiStr === '低体重' &&
      (body.fatStr === '极好' || body.fatStr === '良好' || body.fatStr === '一般') &&
      (body.whrStr === '中' || body.whrStr === '低')) {
      this.explainContent5 = true
    } else if (body.bmiStr === '低体重' &&
      (body.fatStr === '较胖' || body.fatStr === '很胖') &&
      (body.whrStr === '中' || body.whrStr === '低')) {
      this.explainContent6 = true
    } else if (body.bmiStr === '低体重' &&
      (body.fatStr === '极好' || body.fatStr === '良好' || body.fatStr === '一般') &&
      (body.whrStr === '高')) {
      this.explainContent7 = true
    } else if (body.bmiStr === '低体重' &&
      (body.fatStr === '较胖' || body.fatStr === '很胖') &&
      (body.whrStr === '高')) {
      this.explainContent8 = true
    } else if ((body.bmiStr === '超重，疾病风险增加' || body.bmiStr === '≥30肥胖，高疾病风险') &&
      (body.fatStr === '极好' || body.fatStr === '良好' || body.fatStr === '一般') &&
      (body.whrStr === '中' || body.whrStr === '低')) {
      this.explainContent9 = true
    } else if ((body.bmiStr === '超重，疾病风险增加' || body.bmiStr === '≥30肥胖，高疾病风险') &&
      (body.fatStr === '较胖' || body.fatStr === '很胖') &&
      (body.whrStr === '中' || body.whrStr === '低')) {
      this.explainContent10 = true
    } else if ((body.bmiStr === '超重，疾病风险增加' || body.bmiStr === '≥30肥胖，高疾病风险') &&
      (body.fatStr === '极好' || body.fatStr === '良好' || body.fatStr === '一般') &&
      (body.whrStr === '高')) {
      this.explainContent11 = true
    } else if ((body.bmiStr === '超重，疾病风险增加' || body.bmiStr === '≥30肥胖，高疾病风险') &&
      (body.fatStr === '较胖' || body.fatStr === '很胖') &&
      (body.whrStr === '高')) {
      this.explainContent12 = true
    }
  }

  private handleLookFitnessPlan() {
    if (this.isFinish) {
      this.$router.replace('/personal-tailor/fitness-plan')
    }
  }
}
